<template>
  <section>
    <title-section
      title="Detalle del mensaje"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
    </title-section>
    <contact-message-main />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ContactMessage',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    ContactMessageMain: () => import('@/components/Contact/Detail/ContactMessageMain.vue')
  },
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>

<style lang="scss" scoped>

</style>
